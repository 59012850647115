<template>
    <!-- ======= Slider ======= -->
  <section id="slider" class="d-flex justify-content-center align-items-center">
    <div class="container position-relative" data-aos="zoom-in" data-aos-delay="100">
      <h1>Mapa de Fosas comunes de Asturias</h1>
      <h2>La elaboración del mapa forma parte de los sucesivos proyectos de investigación llevados a cabo por la Universidad de Oviedo</h2>      
      <router-link to="/mapa-fosas" class="btn-get-started">Ver mapa</router-link>
    </div>
  </section><!-- fin slider -->

  <main id="cuerpo">

<!-- ======= introducción======= -->
<section id="presentacion" class="presentacion">
  <div class="container" data-aos="fade-up">

    <div class="row" v-for="texto in textos">
      <div class="col-lg-6 order-1 order-lg-2" v-if="texto.FOTO!==''">        
        <img v-bind:src="'data:image/jpeg;base64,'+texto.FOTO" class="img-fluid" alt="{{texto.NAME}}">
      </div>
      <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
        <h3>{{texto.NAME}}</h3>
        <div v-html="texto.TXT"></div>       
      </div>
    </div>

  </div>
</section><!-- Fin introducción-->

</main><!-- fin cuerpo -->
</template>

<script>

import getTexto from "../helpers/getTextos.ts"

export default {
  data() {
    return {      
      textos: []
    }
  },
  methods:{                
    async loadTextoInicio(){
      this.textos = await getTexto("inicio")      
    }
  },
  created(){        
      this.loadTextoInicio();
  }
}
</script>