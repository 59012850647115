import fosasApi from "../api/fosasApi"

const getTexto = async(link:String) => {   

    const textos = await getTextosJson();
    
    return (textos.data).filter((texto: { LINK: String; }) => texto.LINK===link)
}

export const getTextosJson = async() => {
    return fosasApi.get('/textos.json')            
}

export default getTexto;