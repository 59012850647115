import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/presentacion',
        name: 'Presentacion',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Presentacion.vue')
    },    
    {
        path: '/mapa-presentacion',
        name: 'MapaPresentacion',      
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/MapaPresentacion.vue')
    },
    {
        path: '/mapa-fosas',
        name: 'MapaFosas',      
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/MapaFosas.vue')
    },
    {
        path: '/concejo/:id',
        name: 'concejo',
        component: () =>
            import ( /* webpackChunkName: "daybook-entry" */ '../views/Concejo.vue'),
        props: (route: { params: { id: any } }) => {
            return {
                id: route.params.id
            } 
        }
    },
    {
        path: '/fosa/:concejo/:id',
        name: 'fosa',
        component: () =>
            import ( /* webpackChunkName: "daybook-entry" */ '../views/Fosa.vue'),
        props: (route: { params: {concejo:any, id: any } }) => {
            return {
                concejo: route.params.concejo,
                id: route.params.id
            } 
        }
    },
    {
        path: '/contacto',
        name: 'Contacto',      
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Contacto.vue')
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router