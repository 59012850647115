import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import base_url from "./api/config";

import './styles/animate.min.css'
import './styles/style.css'
import 'aos/dist/aos.css'

const app = createApp(App);

app.config.globalProperties.baseurl = base_url;

app.use(router).mount('#app')