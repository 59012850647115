<template>
  <Cabecero></Cabecero>
  <router-view/>
  <Pie></Pie>
</template>

<script>
import { defineAsyncComponent } from "vue";

import AOS from "aos";

export default {
    components: {
        Cabecero: defineAsyncComponent(()=>import('./modules/mapafosas/components/Cabecero.vue')),
        Pie: defineAsyncComponent(()=>import('./modules/mapafosas/components/Pie.vue'))
    },
    mounted(){
      AOS.init()
    }
}
</script>
